import { Component } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import { Icon } from 'bv-components';
import { showDangerMessage } from 'modal-helper';
import withStatus from '../../hocs/with_status';
import checkPassword from '../helpers/api';

class Form extends Component {
  constructor(props) {
    super(props);

    this.state = {
      password: '',
      wrongPassword: false,
      showPassword: false,
    };
  }

  componentDidMount() {
    this.passwordInput.focus();
  }

  setShowPassword = (showPassword) => {
    this.setState({
      showPassword,
    });
  };

  onPasswordSubmit = (e) => {
    const { password } = this.state;
    e.preventDefault();

    this.setState({
      wrongPassword: false,
    });

    if (password.trim() === '') {
      this.setState({
        wrongPassword: true,
      });
      return;
    }

    checkPassword(password)
      .then((result) => {
        if (result && result.success) {
          const { location, history, enabled } = this.props;
          const queryParams = new URLSearchParams(location.search);
          const skipDeviceManager = queryParams.get('skip_device_manager');

          history.push(
            `/preferences/twofactorauth/${enabled && skipDeviceManager ? 'sms' : 'devicemanager'}`,
          );
        } else if (result && result.success === false && result.wrongPassword === true) {
          this.setState({
            wrongPassword: true,
          });
        } else {
          showDangerMessage({
            title: t('javascript.twofa.error.general'),
            iconClosable: true,
            icon: false,
            dataLayer: {
              error_message: t('javascript.twofa.error.general'),
              error_key: 'javascript.twofa.error.general',
              error_code: 'E0x00F',
            },
          });
        }
      });
  };

  handlePasswordChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      wrongPassword: false,
    });
  };

  render() {
    const { password, wrongPassword, showPassword } = this.state;

    return (
      <div className="bvs-card twofa__container">
        <form action="" method="post" id="password-form">
          <p className="form-password__enter-password">{t('javascript.twofa.settings.enter_account_password')}</p>
          <div className="form-password__container-input-group">
            <input
              type={showPassword ? 'text' : 'password'}
              name="password"
              id="password_password"
              ref={(node) => { this.passwordInput = node; }}
              className={classnames('bvs-form-control', {
                error: wrongPassword,
                'with-value': password,
              })}
              onChange={this.handlePasswordChange}
              value={password}
            />
            <label htmlFor="password_password" className="bvs-form-control-placeholder">
              {t('javascript.twofa.settings.account_password')}
            </label>
            <Icon
              id={`eye-catcher-${showPassword ? 'active' : 'inactive'}`}
              onClick={() => this.setShowPassword(!showPassword)}
              className="form-password__eye-catcher-icon"
            />
            {wrongPassword
              && (
              <span id="error_message" className="bvs-form-error-msg">
                {t('javascript.twofa.error.invalid_password')}
              </span>
              )}
            <button
              type="submit"
              id="enter_button"
              className="bvs-button is-primary container-input-group__enter-button"
              onClick={this.onPasswordSubmit}
            >
              {t('javascript.twofa.settings.account_password_button')}
            </button>
          </div>
        </form>
      </div>
    );
  }
}

Form.propTypes = {
  enabled: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default withStatus(Form);
